export interface BookingInterface {
  userId: string;
  payment_id: string;
  order_status: number;
  payment_status: number;
  paymentMethod: PaymentMethodEnum;
  bigAndMediumDogs: number;
  smallDogs: number;
  totalDogs: number;
  amount_received: number;
  payment_getway_status: boolean;
  createdAt: string;
  updatedAt: string;
  status: BookingStatusType;
  _id: string;
  packageTitles: string[];
  cartId: string;
  bookingId: string;
  packageID: string;
  pplExtraCharges: number;
  childrenExtraCharges: number;
  picnicCharges: number;
  bigAndMediumDogExtraCharges: number;
  smallDogExtraCharges: number;
  title: string;
  helpText: string;
  child: number;
  dogs: any;
  englishFirstName: string;
  englishLastName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  people: number;
  carPersonFirstName: string;
  carPersonLastName: string;
  carPersonEnglishFirstName: string;
  maxPeople: number;
  maxChild: number;
  totalPrice: number;
  quantity: number;
  price: number;
  inventory: number;
  email: string;
  state: string;
  district: string;
  city: string;
  pincode: string;
  apartment: string;
  maxDogs: number;
  bbq: number;
  roomCategory: string;
  date: string;
  startTime: Date;
  endTime: Date;
  sameAsUser: false;
  carPersonEmail: string;
  carPersonmobileNumber: string;
  arrivalTime: string;
  carPersonEnglishLastName: string;
  dogsEnable: true;
  numberOfDogs: number;
  picnic: number;
  description: string;
  addOns: CartAddOnInterface[];
  cartAddOn: CartAddOnInterface[];
  startDate: any;
  endDate: any;
  user_dogs: DogInterface[];
  hasDog: HasDog;
  timeSlot: TIME_SLOT_TYPE_ENUM;
  timeSlotIndex: number;

}
export interface BookingDateInterface {
  userId: string;
  payment_id: string;
  order_status: number;
  payment_status: number;
  paymentMethod: PaymentMethodEnum;
  bigAndMediumDogs: number;
  smallDogs: number;
  totalDogs: number;
  amount_received: number;
  payment_getway_status: boolean;
  createdAt: string;
  updatedAt: string;
  status: BookingStatusType;
  _id: string;
  packageTitles: string[];
  cartId: string;
  bookingId: string;
  packageID: string;
  pplExtraCharges: number;
  childrenExtraCharges: number;
  picnicCharges: number;
  bigAndMediumDogExtraCharges: number;
  smallDogExtraCharges: number;
  title: string;
  helpText: string;
  child: number;
  dogs: any;
  englishFirstName: string;
  englishLastName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  people: number;
  carPersonFirstName: string;
  carPersonLastName: string;
  carPersonEnglishFirstName: string;
  maxPeople: number;
  maxChild: number;
  totalPrice: number;
  quantity: number;
  price: number;
  inventory: number;
  email: string;
  state: string;
  district: string;
  city: string;
  pincode: string;
  apartment: string;
  maxDogs: number;
  bbq: number;
  roomCategory: string;
  date: string;
  startTime: Date;
  endTime: Date;
  sameAsUser: false;
  carPersonEmail: string;
  carPersonmobileNumber: string;
  arrivalTime: string;
  carPersonEnglishLastName: string;
  dogsEnable: true;
  numberOfDogs: number;
  picnic: number;
  description: string;
  addOns: CartAddOnInterface[];
  cartAddOn: CartAddOnInterface[];
  startDate: any;
  endDate: any;
  user_dogs: DogInterface[];
  hasDog: HasDog;
  timeSlot: TIME_SLOT_TYPE_ENUM;
  timeSlotIndex: number;
}
export enum TIME_SLOT_TYPE_ENUM {
  SINGLE = 1,
  MULTIPLE = 2
}
export enum BookingStatusType {
  Failed = 1,
  Booked = 2,
  Cancelled = 3,
  Pending = 4
}
export enum HasDog {
  YES = 1,
  NO = 2
}
export enum PaymentMethodEnum {
  STRIPE = 1,
  AMAZON_PAY = 2,
  All = 0
}

export interface DogInterface {
  _id: string;
  userId: string;
  dogName: string;
  breedName: string;
  dateOfVaccine: string;
  file: string;
}
export interface CartAddOnInterface {
  maxPeople: number;
  minPeople: number;
  packageId: string;
  price: number;
  title: string;
  addOnPeople: string;
  type: number;
}
export interface BookingCancelInterface {
  userId: string;
  payment_id: string;
  order_status: number;
  payment_status: number;
  bigAndMediumDogs: number;
  smallDogs: number;
  totalDogs: number;
  amount_received: number;
  payment_getway_status: boolean;
  paymentMethod: PaymentMethodEnum;
  createdAt: string;
  updatedAt: string;
  status: BookingStatusType;
  _id: string;
  packageTitles: string[];
  cartId: string;
  bookingId: string;
  packageID: string;
  title: string;
  helpText: string;
  child: number;
  dogs: any;
  englishFirstName: string;
  englishLastName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  people: number;
  carPersonFirstName: string;
  carPersonLastName: string;
  carPersonEnglishFirstName: string;
  maxPeople: number;
  maxChild: number;
  totalPrice: number;
  quantity: number;
  price: number;
  inventory: number;
  email: string;
  state: string;
  district: string;
  city: string;
  pincode: string;
  apartment: string;
  maxDogs: number;
  bbq: number;
  roomCategory: string;
  date: string;
  startTime: Date;
  endTime: Date;
  sameAsUser: false;
  carPersonEmail: string;
  carPersonmobileNumber: string;
  arrivalTime: string;
  carPersonEnglishLastName: string;
  dogsEnable: true;
  numberOfDogs: number;
  picnic: number;
  description: string;
  addOns: CartAddOnInterface[];
  cartAddOn: CartAddOnInterface[];
  startDate: any;
  endDate: any;
  user_dogs: DogInterface[];
  hasDog: HasDog;
  timeSlot: TIME_SLOT_TYPE_ENUM;
}
export interface BookingPendingInterface {
  userId: string;
  payment_id: string;
  order_status: number;
  payment_status: number;
  bigAndMediumDogs: number;
  smallDogs: number;
  totalDogs: number;
  amount_received: number;
  payment_getway_status: boolean;
  paymentMethod: PaymentMethodEnum;
  createdAt: string;
  updatedAt: string;
  status: BookingStatusType;
  _id: string;
  packageTitles: string[];
  cartId: string;
  bookingId: string;
  packageID: string;
  title: string;
  helpText: string;
  child: number;
  dogs: any;
  englishFirstName: string;
  englishLastName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  people: number;
  carPersonFirstName: string;
  carPersonLastName: string;
  carPersonEnglishFirstName: string;
  maxPeople: number;
  maxChild: number;
  totalPrice: number;
  quantity: number;
  price: number;
  inventory: number;
  email: string;
  state: string;
  district: string;
  city: string;
  pincode: string;
  apartment: string;
  maxDogs: number;
  bbq: number;
  roomCategory: string;
  date: string;
  startTime: Date;
  endTime: Date;
  sameAsUser: false;
  carPersonEmail: string;
  carPersonmobileNumber: string;
  arrivalTime: string;
  carPersonEnglishLastName: string;
  dogsEnable: true;
  numberOfDogs: number;
  picnic: number;
  description: string;
  addOns: CartAddOnInterface[];
  cartAddOn: CartAddOnInterface[];
  startDate: any;
  endDate: any;
  user_dogs: DogInterface[];
  hasDog: HasDog;
  timeSlot: TIME_SLOT_TYPE_ENUM;
}

export interface BookingState {
  bookingList: BookingInterface[];
  bookingFormData: BookingInterface;
  bookingDateList: BookingDateInterface[];
  bookingDateFormData: BookingDateInterface;
  bookingCancelList: BookingCancelInterface[];
  bookingCancelFormData: BookingCancelInterface;
  bookingPendingList: BookingPendingInterface[];
  bookingPendingFormData: BookingPendingInterface;
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  pageNoCancel: number;
  pageSizeCancel: number;
  totalRowCancel: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  sortColumnCancel: string;
  sortDirCancel: string;
  sortColumnPending: string;
  sortDirPending: string;
  pageNoPending: number;
  pageSizePending: number;
  totalRowPending: number;
  sortColumnDateBooking: string;
  sortDirDateBooking: string;
  pageNoDateBooking: number;
  pageSizeDateBooking: number;
  totalRowDateBooking: number;
}

export type InitialProfileSelectState = BookingState;
