import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { PackageCategoryInterface, PackageCategoryState } from '../types';
import { StatusTypeEnum } from 'pages/room-category/types';

export const initialState: PackageCategoryState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  packageCategoryList: [],
  packageCategoryFormData: {
    id: '',
    _id: '',
    title: '',
    isActive: StatusTypeEnum.ACTIVE
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'packageCategoryState',
  initialState,
  reducers: {
    updatePackageCategoryFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `packageCategoryFormData.${action.payload.key}`, action.payload.value);
    },
    AddPackageCategory: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdatePackageCategory: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearPackageCategoryFrom: (state) => {
      state.packageCategoryFormData.id = '';
      state.packageCategoryFormData._id = '';
      state.packageCategoryFormData.title = '';
      state.packageCategoryFormData.isActive = StatusTypeEnum.ACTIVE;
    },
    doPackageDelete: (state, action: PayloadAction<any>) => {},
    doPackageCategoryDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doPackageCategoryEdit: (state, action: PayloadAction<any>) => {},
    doGetPackageCategoryList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPackageCategoryList: (state, action: PayloadAction<Array<PackageCategoryInterface>>) => {
      state.packageCategoryList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPackageCategoryForEdit: (state, action: PayloadAction<PackageCategoryInterface>) => {
      state.packageCategoryFormData = action.payload;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
